import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BannerSection from '../containers/SaasModern/Banner';
import VideoSection from '../containers/SaasModern/Video';
import ServiceSection from '../containers/SaasModern/Service';
import CaseStudies from '../containers/SaasModern/CaseStudies';
import TestimonialSection from '../containers/SaasModern/Testimonial';
import Layout from '../containers/Layout';
import LogRocket from 'logrocket';
LogRocket.init('nb5fte/awaken-website-english');

export default () => {
  const Data = useStaticQuery(graphql`
    query {
      awakenJson {
        SERVICES {
          title
          theme
          img {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          desc
          button {
            title
            link
          }
        }
      }
      awakenFrenchJson {
        SERVICES {
          title
          theme
          img {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          desc
          button {
            title
            link
          }
        }
      }
    }
  `);
  let lang = process.env.GATSBY_LANG;
  console.log('lang', lang);
  let localizedData = Data.awakenJson;
  if (lang === 'fr') {
    localizedData = Data.awakenFrenchJson;
  }
  return (
    <Layout>
      <BannerSection />
      <VideoSection />
      {/* <div className="separator" /> */}
      <div id="our_services">
        {localizedData.SERVICES.map((item, index) => {
          return (
            <ServiceSection
              key={`service-${index}`}
              rowContent={{
                theme: index % 2 === 0 ? 'orange' : 'white',
                fluid:
                  (item.img !== null) | undefined
                    ? item.img.childImageSharp.fluid
                    : {},
                title: item.title,
                desc: item.desc,
                button: item.button
              }}
            ></ServiceSection>
          );
        })}
      </div>
      <CaseStudies />
      <div style={{ height: '100px', backgroundColor: '#f16600' }}></div>
      <TestimonialSection />
    </Layout>
  );
};
