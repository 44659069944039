import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import Container from '../../../components/UI/Container';
import TiltShape from '../TiltShape';
import { VideoWrapper } from './video.style';
import awakenVideo from '../../../assets/images/awaken/Awaken-Solutions-Web-Development.mp4';
import awakenVideoFrench from '../../../assets/images/awaken/Awaken-Solutions-Web-Development-French.mp4';
// import awakenVideoPoster from '../../../assets/images/awaken/Awaken-Solutions-Web-Development-Poster-607.png';
import awakenVideoPoster from '../../../assets/images/awaken/Awaken-Solutions-Web-Development-Poster-30.jpg';
import { enFr } from '../../../utils';

const VideoSection = ({
  row,
  contentWrapper,
  title,
  description,
  videoWrapper,
  buttonWrapper,
  button,
  fillButton,
  video
}) => {
  const Data = useStaticQuery(graphql`
    query {
      videoImage: file(
        relativePath: { eq: "image/saasModern/video-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  let lang = process.env.GATSBY_LANG;

  return (
    <VideoWrapper id="video_section">
      <TiltShape color="#f16600" />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading
              {...title}
              className="title"
              content={enFr(
                'Web & app development by professional engineers',
                "Développement web et d'applications par des ingénieurs professionnels"
              )}
            />
            {/* <div class="typing-wrapper">
              <div class="typing-demo">
                by <em>professional</em> engineers
              </div>
            </div> */}
            <Text
              {...description}
              content={enFr(
                'Awaken Solutions is a web and app development firm based in Montreal, Canada serving clients around the world. We are a team of passionate engineers and developers who pride ourselves on delivering outstanding results and professional service.',
                "Awaken Solutions est une entreprise de développement de sites web et d'applications basée à Montréal, au Canada, qui sert des clients dans le monde entier. Nous sommes une équipe d'ingénieurs et de développeurs passionnés qui sont fiers de fournir des résultats exceptionnels et un service professionnel."
              )}
            />
          </Box>
          {/* <ContactTabs /> */}
          <Box {...videoWrapper}>
            <Fade bottom>
              {/* <Image
                fluid={Data.videoImage.childImageSharp.fluid}
                alt="video image"
              /> */}
              <video
                {...video}
                poster={awakenVideoPoster}
                // autoPlay
                muted
                controls
                controlsList="nodownload"
                disablePictureInPicture
              >
                <source
                  src={lang === 'fr' ? awakenVideoFrench : awakenVideo}
                  type="video/mp4"
                />
              </video>
            </Fade>
          </Box>
        </Box>
      </Container>
    </VideoWrapper>
  );
};

VideoSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  videoWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
  video: PropTypes.object
};

VideoSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: '40px'
  },
  videoWrapper: {
    width: '100%',
    borderRadius: '10px',
    mt: ['10px', '10px', '10px', '20px', '20px'],
    pl: ['15px', '15px', '15px', '100px', '200px'],
    pr: ['15px', '15px', '15px', '100px', '200px']
  },
  video: {
    width: '100%',
    pl: ['15px', '15px', '15px', '75px', '120px'],
    pr: ['15px', '15px', '15px', '75px', '120px']
  },
  title: {
    fontSize: ['40px', '40px', '40px', '46px', '46px'],
    fontWeight: '300',
    color: '#333',
    letterSpacing: '-0.025em',
    mt: ['0', '0', '0', '20px', '20px'],
    mb: ['10px', '10px', '10px', '10px', '10px'],
    pl: ['0', '0', '0', '50px', '50px'],
    pr: ['0', '0', '0', '50px', '50px'],
    lineHeight: '1.2',
    textAlign: 'center'
  },
  description: {
    fontSize: ['18px', '18px', '18px', '22px', '22px'],
    fontWeight: ['400', '400', '400', '300', '300'],
    color: '#333',
    lineHeight: '1.75',
    mt: ['50px', '50px', '50px', '50px', '50px'],
    mb: '0',
    pl: ['0', '0', '0', '50px', '100px'],
    pr: ['0', '0', '0', '50px', '100px'],
    textAlign: 'center'
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '700',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto'
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px'
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto'
  }
};

export default VideoSection;
