import styled from 'styled-components';

export const VideoWrapper = styled.section`
  padding: 50px 0 50px 0;
  background-color: #f8f8f8;
  background-size: cover;
  background-position: top center;
  .contactBoxHolder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contactBox {
    width: 500px;
    height: 500px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  .title {
    // text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;
