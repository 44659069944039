import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Img from 'gatsby-image';
import { openModal, closeModal } from '@redq/reuse-modal';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Image from '../../../components/Image';
import Container from '../../../components/UI/Container';
import LoginModal from '../LoginModal';

import ServiceSectionWrapper from './service.style';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ServiceSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  textAreaRow,
  rowContent
}) => {
  if (rowContent?.theme === 'orange') {
    button.colors = 'secondaryWithBg';
  } else {
    button.colors = 'primaryWithBg';
  }

  const handleLoginModal = () => {
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        default: {
          width: '80%',
          height: 300,
          margin: 0
        },
        margin: 0
      },
      component: LoginModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  return (
    <ServiceSectionWrapper className={rowContent?.theme}>
      <Container fullWidth noGutter className="serviceRow">
        <Container className="serviceRowContainer">
          <Box {...col} {...imageArea} className="image_area">
            <Card {...imageWrapper}>
              <Fade left>
                {/* <Image src={rowContent?.img} alt={rowContent?.title} /> */}
                {/* <Image fluid={rowContent?.fluid} alt={rowContent?.title} /> */}
                <Img fluid={rowContent?.fluid} alt={rowContent?.title} />
              </Fade>
            </Card>
          </Box>
          <Box {...col} {...textArea} className="text_area">
            <Heading {...title} content={rowContent?.title} />
            <Text {...description} content={rowContent?.desc} />
            <Box className="button_box">
              <Button
                {...button}
                title={rowContent?.button?.title}
                onClick={handleLoginModal}
              />
            </Box>
          </Box>
        </Container>
      </Container>
    </ServiceSectionWrapper>
  );
};

ServiceSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  textAreaRow: PropTypes.object
};

ServiceSection.defaultProps = {
  row: {
    // flexBox: true,
    // flexWrap: 'wrap',
    // ml: '-15px',
    // mr: '-15px'
  },
  textAreaRow: {
    flexDirection: 'row-reverse'
  },
  col: {
    pr: '15px',
    pl: '15px'
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '50%']
  },
  imageArea: {
    pt: ['35px', '35px', '0', '0', '0'],
    pr: ['40px', '40px', '40px', '100px', '100px'],
    pl: ['40px', '40px', '40px', '100px', '100px'],
    width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true
  },
  imageWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    boxShadow: 'none'
  },
  title: {
    fontSize: ['30px', '32px', '36px', '40px', '40px'],
    fontWeight: ['400', '400', '400', '300', '300'],
    color: 'inherit',
    letterSpacing: '-0.025em',
    mb: '20px',
    lineHeight: '1.25'
  },
  description: {
    fontSize: ['18px', '18px', '18px', '22px', '22px'],
    fontWeight: ['400', '400', '400', '300', '300'],
    color: 'inherit',
    lineHeight: '1.8',
    mb: '33px'
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg'
  }
};

export default ServiceSection;
