import styled from 'styled-components';
import BgImage from '../../../assets/images/saasModern/dash-bg.png';
import { themeGet } from 'styled-system';

const ServiceSectionWrapper = styled.section`
  background: ${themeGet('colors.lightGreyBkg', '#f8f8f8')};
  &.orange {
    background: ${themeGet('colors.primary', '#f16600')};
    color: white;
  }
  .serviceRow {
  }
  .serviceRowContainer {
    display: flex;
    flex-wrap: wrap;
  }
  padding: 150px 0 150px 0;

  @media (max-width: 1600px) {
    padding: 150px 0 150px 0;
  }
  @media (max-width: 1400px) {
    padding: 125px 0 125px 0;
  }
  @media (max-width: 1300px) {
    padding: 100px 0 100px 0;
  }
  @media (max-width: 1199px) {
    padding: 100px 0 100px 0;
  }
  @media (max-width: 767px) {
    padding: 30px 0 60px 0;
  }
  // .image_area {
  //   padding: 0 100px 0 100px;
  //   @media (max-width: 767px) {
  //     padding: 35px 20px 0 20px;
  //   }
  // }
  .text_area {
    padding: 0 50px 0 15px;
    @media (max-width: 767px) {
      padding: 50px 20px 0 20px;
      h2,
      p,
      .button_box {
        text-align: center;
      }
    }
  }
`;

export default ServiceSectionWrapper;
