import styled from 'styled-components';

export const TestimonialSlideWrapper = styled.div`
  .glide__bullets {
    margin-top: 45px;
    text-align: center;
    @media (max-width: 575px) {
      margin-top: 30px;
    }
    .glide__bullet {
      width: 12px;
      height: 12px;
      background: #e7f1ed;
      margin: 5px;
      transition: 0.15s ease-in-out;
      &:hover {
        background: #d8e2de;
      }
      &.glide__bullet--active {
        background: #c9cecc;
      }
    }
  }

  .headline {
    font-weight: 600;
    font-size: 36px;
    color: #333333;
    margin: 0;
  }
  .companyLine {
    font-size: 14px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    color: #333333;
    margin: 15px 0 35px 0;
    .company {
      font-weight: 800;
    }
    .city {
      font-weight: 400;
      margin-left: 10px;
    }
  }
  .subHeading {
    font-size: 14px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    color: #333333;
    font-weight: 800;
    margin: 0 0 5px 0;
  }
  .thin {
    font-weight: 400;
  }
  .description {
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    // text-align: justify;
  }
  .devTech {
    margin: 7px 0 35px 0;
    display: flex;
    justify-content: space-between;
  }
  .testimonial {
    font-style: italic;
    color: #606060;
    font-weight: 400;
  }
  .testimonialName {
    text-align: right;
    margin-top: 15px;
  }
  .logo {
    margin-top: 10px;
    max-width: 160px;
    max-height: 50px;
  }
`;

export const TestimonialItem = styled.div`
  position: relative;
  display: block;
  background: #fff;
  border: 1px solid #f2f4f7;
  border-radius: 5px;
  padding: 40px;

  @media (max-width: 575px) {
    padding: 30px;
  }
`;

export const TestimonialMeta = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const AuthorImage = styled.div`
  width: 180px;
  margin-right: 15px;
  @media (max-width: 575px) {
  }
`;
