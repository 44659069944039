import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import 'rc-tabs/assets/index.css';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Container from '../../../components/UI/Container';
import TiltShape from '../TiltShape';
import { enFr } from '../../../utils';
import SectionWrapper from './caseStudies.style';

const CaseStudies = ({ headingWrapper, title, row, col, headline }) => {
  const Data = useStaticQuery(graphql`
    query {
      awakenJson {
        CLIENTS {
          caseStudy
          headline
          company
          city
          description
          development {
            content
          }
          technology {
            content
          }
          review
          name
          designation
          logoGrey {
            publicURL
          }
        }
      }
      awakenFrenchJson {
        CLIENTS {
          caseStudy
          headline
          company
          city
          description
          development {
            content
          }
          technology {
            content
          }
          review
          name
          designation
          logoGrey {
            publicURL
          }
        }
      }
    }
  `);

  let lang = process.env.GATSBY_LANG;
  let localizedData = Data.awakenJson;
  if (lang === 'fr') {
    localizedData = Data.awakenFrenchJson;
  }
  let caseStudies = localizedData.CLIENTS.filter((item) => item.caseStudy);
  return (
    <SectionWrapper id="case_studies">
      <TiltShape color="#f16600" />
      <Container>
        <Box {...headingWrapper}>
          <Heading {...title} content={enFr('Case Studies', 'Études de cas')} />
        </Box>
        <Tabs
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
          className="update-screen-tab"
        >
          {caseStudies.map((item, index) => (
            <TabPane forceRender={true} tab={item.company} key={index}>
              <Box className="caseStudyRow" {...row}>
                <Box {...col} className="leftArea">
                  <Text
                    {...headline}
                    className="headline"
                    content={item.headline}
                  />
                  <div className="companyLine">
                    <span className="company">{item.company}</span>
                    <span className="city">{item.city}</span>
                  </div>
                </Box>
                <Box {...col} className="rightArea">
                  <Box className="devTech">
                    <div className="dev">
                      <div className="subHeading">
                        {enFr('Development', 'Développement')}
                      </div>
                      <div className="subHeading thin">
                        {item.development
                          ? item.development.map((item2, index2) => (
                              <div key={index2}>{item2.content}</div>
                            ))
                          : ''}
                      </div>
                    </div>
                    <div className="tech">
                      <div className="subHeading">
                        {enFr('Technology', 'Technologie')}
                      </div>
                      <div className="subHeading thin">
                        {item.technology
                          ? item.technology.map((item3, index3) => (
                              <div key={index3}>{item3.content}</div>
                            ))
                          : ''}
                      </div>
                    </div>
                  </Box>
                </Box>
                <Box {...col} className="leftArea">
                  <div className="subHeading">{enFr('Project', 'Projet')}</div>
                  <div className="description">{item.description}</div>
                </Box>
                <Box {...col} className="rightArea">
                  <Box className="testimonialWrapper">
                    <div className="subHeading">
                      {enFr('Testimonial', 'Témoignage')}
                    </div>
                    <div className="description testimonial">{item.review}</div>
                    <div className="testimonialName">
                      <div className="subHeading">{item.name}</div>
                      <div className="subHeading thin">{item.designation}</div>
                      <img
                        className="logo"
                        src={item.logoGrey.publicURL}
                        alt={`testimonial-logo-${index}`}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </SectionWrapper>
  );
};

CaseStudies.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  headingWrapper: PropTypes.object,
  headline: PropTypes.object,
  title: PropTypes.object
};

CaseStudies.defaultProps = {
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pr: ['0', '0', '0', '15px', '15px'],
    pl: ['0', '0', '0', '15px', '15px']
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  title: {
    textAlign: 'center',
    fontSize: ['30px', '32px', '36px', '40px', '40px'],
    fontWeight: ['400', '400', '400', '400', '400'],
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.25'
  },
  headingWrapper: {
    mb: ['40px', '60px']
  },
  headline: {
    margin: 0,
    textAlign: 'left',
    fontSize: ['30px', '32px', '36px', '40px', '40px'],
    fontWeight: ['600', '600', '600', '600', '600'],
    color: '#333'
  }
};

export default CaseStudies;
