import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { openModal, closeModal } from '@redq/reuse-modal';
import Icon from 'react-icons-kit';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import Container from '../../../components/UI/Container';
import { BannerWrapper } from './banner.style';
import LoginModal from '../LoginModal';
import { enFr } from '../../../utils';
// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const BannerSection = ({
  row,
  contentWrapper,
  title,
  subTitle,
  description,
  videoWrapper,
  buttonWrapper,
  button,
  fillButton,
  video
}) => {
  const Data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "image/saasModern/banner-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const handleLoginModal = () => {
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        default: {
          width: '80%',
          height: 300,
          margin: 0
        },
        margin: 0
      },
      component: LoginModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  return (
    <BannerWrapper id="welcome">
      {/* <TiltShape /> */}
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading
              {...title}
              className="title"
              content={enFr(
                'Welcome to Awaken Solutions',
                'Bienvenue chez Solutions Awaken'
              )}
            />
            <Heading
              {...subTitle}
              className="subTitle"
              content={enFr(
                'Working with code and humans since 2001',
                'Travaillant avec le code et les humains depuis 2001'
              )}
            />
            <Box {...buttonWrapper}>
              <a href="#1">
                <Button
                  {...fillButton}
                  title={enFr(
                    'SCHEDULE A 15-MIN CALL',
                    'PRENEZ UN RENDEZ-VOUS TÉLÉPHONIQUE'
                  )}
                  icon={<Icon icon={ic_phone} size={30} />}
                  iconPosition="left"
                  onClick={handleLoginModal}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  description: PropTypes.object,
  videoWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
  video: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: '40px'
  },
  videoWrapper: {
    width: '100%',
    borderRadius: '10px',
    mt: ['10px', '10px', '10px', '10px', '10px'],
    pl: ['15px', '15px', '15px', '50px', '100px'],
    pr: ['15px', '15px', '15px', '50px', '100px']
  },
  video: {
    width: '100%',
    pl: ['15px', '15px', '15px', '75px', '120px'],
    pr: ['15px', '15px', '15px', '75px', '120px']
  },
  title: {
    fontSize: ['40px', '40px', '40px', '46px', '46px'],
    fontWeight: '300',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: ['0', '0', '0', '20px', '20px'],
    mb: ['10px', '10px', '10px', '10px', '10px'],
    lineHeight: '1.2',
    textAlign: 'center'
  },
  subTitle: {
    fontSize: ['22px', '22px', '22px', '26px', '26px'],
    fontWeight: '300',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: ['0', '0', '0', '20px', '20px'],
    mb: ['10px', '10px', '10px', '10px', '10px'],
    lineHeight: '1.2',
    textAlign: 'center'
  },
  description: {
    fontSize: ['18px', '18px', '18px', '22px', '22px'],
    fontWeight: ['400', '400', '400', '300', '300'],
    color: '#fff',
    lineHeight: '1.75',
    mt: ['50px', '50px', '50px', '50px', '50px'],
    mb: '0',
    pl: ['0', '0', '0', '50px', '100px'],
    pr: ['0', '0', '0', '50px', '100px'],
    textAlign: 'center'
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '700',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto'
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px'
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto'
  }
};

export default BannerSection;
