import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Container from '../../../components/UI/Container';
import GlideCarousel from '../../../components/GlideCarousel';
import GlideSlide from '../../../components/GlideCarousel/glideSlide';
import { enFr } from '../../../utils';

import { TestimonialSlideWrapper, TestimonialItem } from './testimonial.style';

const TestimonialSection = ({ sectionWrapper, headingWrapper, title }) => {
  const Data = useStaticQuery(graphql`
    query {
      awakenJson {
        CLIENTS {
          name
          designation
          review
          company
          # logoGrey {
          #   childImageSharp {
          #     fluid(quality: 100) {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
          logoGrey {
            publicURL
          }
        }
      }
      awakenFrenchJson {
        CLIENTS {
          name
          designation
          review
          company
          # logoGrey {
          #   childImageSharp {
          #     fluid(quality: 100) {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
          logoGrey {
            publicURL
          }
        }
      }
    }
  `);

  let lang = process.env.GATSBY_LANG;
  let localizedData = Data.awakenJson;
  if (lang === 'fr') {
    localizedData = Data.awakenFrenchJson;
  }
  const clientTestimonials = localizedData.CLIENTS;

  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    animationDuration: 800,
    perView: 2,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100
        }
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160
        }
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80
        }
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20
        }
      }
    }
  };

  return (
    <Box {...sectionWrapper} as="section" id="testimonial_section">
      <Container noGutter>
        <Box {...headingWrapper}>
          <Heading {...title} content={enFr('Testimonials', 'Témoignages')} />
        </Box>
        <TestimonialSlideWrapper>
          <GlideCarousel
            options={carouselOptions}
            carouselSelector="testimonial__slider"
            controls={false}
            bullets={true}
            numberOfBullets={clientTestimonials.length}
          >
            <>
              {clientTestimonials.map((item, index) => (
                <GlideSlide key={`testimonial-slide-${index}`}>
                  <TestimonialItem>
                    {/* <Heading as="h3" content={item.company} {...reviewTitle} /> */}
                    <Box className="testimonialWrapper">
                      <div className="description testimonial">
                        {item.review}
                      </div>
                      <div className="testimonialName">
                        <div className="subHeading">{item.name}</div>
                        <div className="subHeading thin">
                          {item.designation}
                        </div>
                        {/* <Image
                          fluid={item.logoGrey.childImageSharp.fluid}
                          alt={`testimonial-image-${index}`}
                        /> */}
                        <img
                          className="logo"
                          src={item.logoGrey.publicURL}
                          alt={`testimonial-logo-${index}`}
                        />
                      </div>
                    </Box>
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </TestimonialSlideWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  headingWrapper: PropTypes.object,
  title: PropTypes.object
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    backgroundColor: '#f16600',
    pt: ['60px', '80px', '90px', '100px', '100px'],
    pb: ['60px', '80px', '90px', '100px', '100px']
  },
  secTitleWrapper: {
    mb: ['40px', '60px']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67'
  },
  reviewTitle: {
    fontSize: ['15px', '16px'],
    fontWeight: '500',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '13px'
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px'
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0
  },
  title: {
    textAlign: 'center',
    fontSize: ['30px', '32px', '36px', '40px', '40px'],
    fontWeight: ['400', '400', '400', '400', '400'],
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.25'
  },
  headingWrapper: {
    mb: ['40px', '60px']
  }
};

export default TestimonialSection;
