import styled from 'styled-components';
import BannerBG from '../../../assets/images/saasModern/banner-texture.png';
import HandsDesk from '../../../assets/images/awaken/awaken_slider_hands_1920_tiny.png';

export const BannerWrapper = styled.section`
  padding: 150px 0 50px 0;
  background-color: #f16600;
  background-image: url(${HandsDesk});
  // background-image: url(${HandsDesk}),
  //   linear-gradient(35deg, #f16600 0%, #f16600 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 580px;
  @media (max-width: 4000px) {
    height: 950px;
  }
  @media (max-width: 3000px) {
    height: 850px;
  }
  @media (max-width: 2200px) {
    height: 750px;
  }
  @media (max-width: 1800px) {
    height: 700px;
  }
  @media (max-width: 1600px) {
    height: 650px;
  }
  @media (max-width: 1350px) {
    height: 600px;
  }
  @media (max-width: 1199px) {
    height: 550px;
  }
  @media (max-width: 990px) {
    height: 500px;
    background-size: contain;
  }
  @media (max-width: 767px) {
    height: 500px;
    background-size: contain;
  }
  @media (max-width: 575px) {
    height: 550px;
    background-size: contain;
  }
  @media (max-width: 350px) {
    height: 600px;
    background-size: contain;
  }
  .contactBoxHolder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contactBox {
    width: 500px;
    height: 500px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  // .title {
  //   text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  // }
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;
